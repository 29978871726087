import React from "react";

export default function Bitcoin(){
    const [bitcoinData, setBitcoinData] = React.useState(
        {
        name: "",
        imageUrl: "",
        currentPrice: 0, 
        high: 0, 
        low: 0
        }
    );

    React.useEffect(() => {
        fetch("https://api.coingecko.com/api/v3/coins/bitcoin")
        .then(res => {
            if (!res.ok) {
                throw Error("Something went wrong")
            }
            return res.json()
        })
        .then(data => {
            setBitcoinData(
                {
                name: data.name,
                imageUrl: data.image.small,
                currentPrice: data.market_data.current_price.usd, 
                high: data.market_data.high_24h.usd, 
                low: data.market_data.low_24h.usd
                }
            );
        })
        .catch(err => console.error(err))
    },[])

    return(
        <div className="bitcoin">
            <img className="bitcoin_img" src={bitcoinData.imageUrl} />
            <div className="bitcoin_data">
                <span className="bitcoin_name" >{bitcoinData.name}:</span>
                <p className="bitcoin_current">{bitcoinData.currentPrice} $</p>
            </div>
        </div>
    );
};