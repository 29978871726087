import React from "react";

export default function Time() {
    const [date, setDate] = React.useState(new Date());

    const hours = date.getHours();
    const minutes = date.getMinutes();
 
    const month = date.getMonth() + 1;
    const day = date.getDate();
    let weekday = "";

    React.useEffect(() => {
        const interval = setInterval(() => setDate(new Date()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    let timeOfDay
    if (hours < 11) {
        timeOfDay = "おはようございます"
    } else if (hours >= 11 && hours < 18) {
        timeOfDay = "こんにちは"
    } else {
        timeOfDay = "こんばんは"
    };

    switch (date.getUTCDay()) {
        case 0:
          weekday = "日曜日";
          break;
        case 1:
          weekday = "月曜日";
          break;
        case 2:
          weekday = "火曜日";
          break;
        case 3:
          weekday = "水曜日";
          break;
        case 4:
          weekday = "木曜日";
          break;
        case 5:
          weekday = "金曜日";
          break;
        case 6:
          weekday = "土曜日";
      };
      
    return (
        <div className="time">
            <h2>{timeOfDay}</h2>
            <h1>{hours < 10? "0" + hours : hours} : {minutes < 10? "0" + minutes : minutes}</h1>
            <h3>今日は、{weekday}、<span className="date_span">{day < 10? "0" + day : day}.{month < 10? "0" + month : month}</span> ですね。</h3>
        </div>
    )
}