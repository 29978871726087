import React from "react"

export default function F1(){
    const [raceName, setRaceName] = React.useState("");
    const [date, setDate] = React.useState(new Date());
    const [standings, setStandings] = React.useState([{Driver: {familyName: ""}, points: 0}, {Driver: {familyName: ""}, points: 0}, {Driver: {familyName: ""}, points: 0}]);

    React.useEffect(() => {
        fetch("https://ergast.com/api/f1/current/next.json")
            .then(res => res.json())
            .then(data => {
                setRaceName(data.MRData.RaceTable.Races[0].raceName.replace("Grand Prix","GP"));
                const d = new Date(data.MRData.RaceTable.Races[0].date + " " + data.MRData.RaceTable.Races[0].time.replace("Z"," GMT"));
                setDate(d);
            })
            .catch(err => console.error(err));
    },[]);

    React.useEffect(() => {
        fetch("https://ergast.com/api/f1/current/driverStandings.json")
            .then(res => res.json())
            .then(data => {
                setStandings(data.MRData.StandingsTable.StandingsLists[0].DriverStandings);
            })
            .catch(err => console.error(err));
    },[])

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return(
        <div className="f1">
            <h3>🏁 F1 {raceName}, {day < 10? "0" + day : day}.{month < 10? "0" + month : month} - {hours < 10? "0" + hours : hours}:{minutes < 10? "0" + minutes : minutes}</h3>
            <ul className="f1_standings">
                <li>{standings[0].Driver.familyName}: {standings[0].points} pt</li>
                <li>{standings[1].Driver.familyName}: {standings[1].points} pt</li>
                <li>{standings[2].Driver.familyName}: {standings[2].points} pt</li>
            </ul>
        </div>
    );
};
