import React from "react";

export default function Weather(){
    const [weatherData, setWeatherData] = React.useState({loaded: false, temp: 0, location: "", desc: "", iconString: "0"})
    React.useEffect(() => {
        navigator.geolocation.getCurrentPosition(position => {
            fetch(`https://apis.scrimba.com/openweathermap/data/2.5/weather?lat=${position.coords.latitude}&lon=${position.coords.longitude}&units=metric`)
                .then(res => {
                    if (!res.ok) {
                        throw Error("Weather data not available")
                    }
                    return res.json()
                })
                .then(data => {
                    let icon = "";
                    switch(data.weather[0].icon.slice(0, 2)) {
                        case "01": //"clear sky/sun"
                            icon = "1";
                          break;
                        case "02": //"sun and clouds"
                            icon = "9";
                          break;
                        case "03": //"clouds"
                            icon = "2";
                          break;
                        case "04": //"clouds"
                            icon = "2";
                          break;
                        case "50": //"clouds"
                            icon = "2";
                          break;
                        case "09": //"heavy rain"
                            icon = "4";
                          break;
                        case "10": //"light rain"
                            icon = "3";
                          break;
                        case "11": //"thunderstorm"
                            icon = "7";
                          break;
                        case "13": //"snow"
                            icon = "5";
                          break;
                        default:
                            icon = ":";
                      }
                    setWeatherData({loaded: true, temp: data.main.temp, location: data.name, desc: data.weather[0].description, iconString: icon});
                })
                .catch(err => console.error(err))
        });
    }, []);
    console.log(weatherData.desc);

    return(
        <div className="weather">
            <div className="weather_container">
                <div className="weather_icons">{weatherData.iconString}</div>
                <div className="weather_temp">{Math.round(weatherData.temp)}</div>
                <span className="weather_degree">°</span>
            </div>
            <p className="weather_location">{weatherData.location}</p>
        </div>
    )
};