import React from "react";
import Bitcoin from "./components/Bitcoin";
import F1 from "./components/F1";
import Weather from "./components/Weather"
import Time from "./components/Time";
import Ramentimer from "./components/Ramentimer";

import background from "./img/background.jpg";

export default function JapanDashboard(){
    const [theme, setTheme] = React.useState( localStorage.getItem("Dashboard_theme") || "japan");
    const [author, setAuthor] = React.useState("");
    const [backgroundLink, setBackgroundLink] = React.useState("");
    const [backgroundError, setBackgroundError] = React.useState(false);
    const [formData, setFormData] = React.useState({newTheme: ""});

    //funktioniert
    //Background
    //unsplash accessKey: "wCGq_I-oZt3JrZwtY_cK2lxR7cn-ve5es2r_L5FBChc"
    React.useEffect(() => {
        //fetch(`https://api.unsplash.com/photos/random/?client_id=wCGq_I-oZt3JrZwtY_cK2lxR7cn-ve5es2r_L5FBChc&query=${theme}`)
        fetch(`https://apis.scrimba.com/unsplash/photos/random?query=${theme}`)
            .then(res => res.json())
            .then(data => {
                document.body.style.backgroundImage = `url(${data.urls.regular})`;
                setAuthor(data.user.username);
                setBackgroundLink(data.links.html);
            })
            .catch((error) => {
                document.body.style.backgroundImage = `url(${background})`;
                setBackgroundError(true);
                console.log(error);
            });
        }, [theme]);

    function handleChange(event) {
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }})};

    function setNewTheme(event){
        event.preventDefault();
        localStorage.setItem("Dashboard_theme", formData.newTheme)
        setTheme(formData.newTheme);
        setFormData({newTheme: ""});
    };

    return(
        <div className="wrapper">
            <Bitcoin /> 
            <F1 />
            <Weather />
            <Time />
            <Ramentimer/>
            <div className="background_controller">
               {backgroundError? <p className="error">Um..seems like you have no internet or a typo?</p> : <a className="background_author" target="_blank" href={backgroundLink}>{author} on Unsplash.com</a>}
               <p className="background_theme">Current Theme: {theme.toUpperCase()}</p>
               <form className="background_form">
                   <input
                    type="text"
                    placeholder="Type in new theme"
                    onChange={handleChange}
                    name="newTheme"
                    value={formData.newTheme} />
                    <button onClick={setNewTheme}>submit</button>
               </form>
            </div>
        </div>   
    );
};