import React from "react"
import useSound from 'use-sound';

import beep from "../sound/Wecker-sound.mp3"

export default function Ramentimer(){
    const [seconds, setSeconds] = React.useState(0);
    const [timerIsOn, setTimerIsOn] = React.useState(false);
    const [countingDown, setCountingDown] = React.useState(false);
    const [countDownInterval, setCountDownInterval] = React.useState("");
    const [countUpInterval, setCountUpInterval] = React.useState("");
    const [alarmIsOn, setAlarmIsOn] = React.useState(false);

    //clearing running intervals on unmount
    React.useEffect(() => {
        return () => {
            clearInterval(countDownInterval);
            clearInterval(countUpInterval);
        };
    }, []);

    const [play, { stop }] = useSound(beep);
    //play()
    //() => stop()

    //iO
    function increaseMinutes() {
        if(alarmIsOn){
            stop();
            setAlarmIsOn(false);
        } else {
            setCountingDown(true);
            if(seconds < 99*60){ //Zählerbegrenzer 99min
                setSeconds(oldSeconds => oldSeconds + 60);
            };
        };
    };

    //iO
    function increaseSeconds() {
        if(alarmIsOn){
            stop();
            setAlarmIsOn(false);
        } else {
            setCountingDown(true);
            if(seconds < 99*60 + 59){ //Zählerbegrenzer 99min59sec
                setSeconds(oldSeconds => oldSeconds + 1);
            };
        };
    };

    //iO
    // Start/Stop Button: Startet den Countdown, Countup oder pausiert diese.
    function startStop() {
        if(alarmIsOn){
            clearAlarm();
        } else if(seconds > 0 && timerIsOn === false) { //restzeit und nicht On
            if(countingDown === true) { //runterzählen
                countDown();
            } else if(countingDown === false) { //raufzählen (default bei 00:00)
                countUp();
            };
        } else if(seconds === 0 && timerIsOn === false) {
          countUp();
        } else { 
          if(timerIsOn === true) { 
            clearInterval(countDownInterval);
            clearInterval(countUpInterval);
            setTimerIsOn(false);
          };
        };
    };

    //iO
    function countDown(){
        const interval = setInterval(() => setSeconds(oldSeconds => oldSeconds - 1), 1000);
        setCountDownInterval(interval);
        setTimerIsOn(true);
    };

    //iO
    function countUp(){
        const interval = setInterval(() => setSeconds(oldSeconds => oldSeconds + 1), 1000);
        setCountUpInterval(interval);
        setTimerIsOn(true);
    };

    //iO
    function reset() {
        if(alarmIsOn){
            stop();
            setAlarmIsOn(false);
        } else {
            setSeconds(0);
            setCountingDown(false);
            setTimerIsOn(false);
            clearInterval(countDownInterval);
            clearInterval(countUpInterval);
        };
    };

    function clearAlarm() {
        stop();
        setAlarmIsOn(false);
    };

    //iO
    //split seconds in min/sec for UI
    let displayedMinutes = Math.floor(seconds / 60);
    let displayedSeconds = seconds % 60;

    //check for alarm
    if (seconds === 0 && countingDown) {
        reset();
        play();
        setAlarmIsOn(true);
    };

    return(
        <div className="ramentimer">
            <div className="ramentimer_bunny">
                <span className="ramentimer_display">{displayedMinutes < 10? "0" + displayedMinutes : displayedMinutes}:{displayedSeconds < 10? "0" + displayedSeconds : displayedSeconds}</span>
                <button className="ramentimer_minute-btn" onClick={increaseMinutes}></button>
                <button className="ramentimer_second-btn" onClick={increaseSeconds}></button>
                <button className="ramentimer_start-btn" onClick={startStop}></button>
                <button className="ramentimer_reset-btn" onClick={reset}></button>
            </div>
        </div>
    )
}